<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col cols="6" md="4">
                        <div>
                            <b-form-group label="Search Audio">
                                <b-form-input v-model="audioSearchQuery" @input="getZapeAudios"
                                    placeholder="Search..." />
                            </b-form-group>

                        </div>
                    </b-col>
                    <b-col cols="6" md="4">
                    </b-col>
                    <TextToAudioModel :manage="isManageTts" :token="bearerToken" @fetchThemes="getZapeAudios" />
                </b-row>
            </div>

            <b-overlay :show="show" rounded="sm">
                <b-table :items="allAudios.data" responsive :fields="fields" class="mb-0" id="custom-table">
                    <template #cell(text)="data">
                        <div class="d-flex align-items-center">
                            {{ data.item.text }}
                        </div>
                    </template>

                    <template #cell(url)="data">
                        <div class="d-flex align-items-center">
                            {{ data.item.url == null ? 'Upload to playht' : data.item.url }}
                        </div>
                    </template>

                    <template #cell(action)="data">
                        <div class="d-flex align-items-center">
                            <b-dropdown variant="link" no-caret>
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>
                                <b-dropdown-item>
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50">Edit</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <div style="display: flex; flex-direction: row-reverse">
                    <div>
                        <pagination class="m-t-10" :data="allAudios" :limit=2 @pagination-change-page="getZapeAudios">
                        </pagination>
                    </div>
                </div>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BOverlay,
    BFormGroup,
    BTableSimple,
    BThead,
    BFormSelect,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
} from "bootstrap-vue";
import { BBreadcrumb } from "bootstrap-vue";
import axios from "axios";
export default {
    components: {
        TextToAudioModel: () => import("@/views/components/Tts/TextToAudioModel.vue"),
        BBreadcrumb,
        BCard,
        BOverlay,
        BFormGroup,
        BAvatar,
        BRow,
        BCol,
        BFormSelect,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BTableSimple,
        BThead,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
    },

    data() {
        return {
            isManageTts: false,
            show: false,
            fields: [
                { key: "text", label: "TEXT" },
                { key: "url", label: "URL" },
                { key: "action", label: "ACTION" },
            ],
            allAudios: [],
            audioSearchQuery: '',
            allGrades: [],
            selectGrade: {},
            bearerToken: null,
        };
    },
    mounted() {
        this.isManageTts = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "manage_audios");
        this.bearerToken = "Bearer " + localStorage.getItem("_t");
    },
    created() {
        this.getZapeAudios();
    },
    methods: {
        refreshTextFromDatabase() {
            this.show = true;
            axios
                .get(process.env.VUE_APP_API_URL + '/text/audio/refresh', {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((get_response) => {
                    this.show = false;
                    this.getZapeAudios();
                }).catch((error) => {
                    console.log('issue in text v')
                    this.show = false;
                })
        },
        getZapeAudios(page = 1, query) {
            this.show = true;
            query = this.audioSearchQuery;

            axios
                .get(process.env.VUE_APP_API_URL + `/zape-cms/audios/pagination?page=${page}&query=${query}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((get_response) => {
                    this.show = false;
                    this.allAudios = get_response.data;
                }).catch((errror) => {
                    this.show = false;
                })
        },
    },
};
</script>
